<template>
  <div>
    <div>
      <el-form :inline="true" :model="queryParam">
        <el-form-item label="考点名称" class="formItemBoxStyle">
          <el-input
            v-model="queryParam.name"
            placeholder="请输入考点名称"
            @input="reloadCompanPlaceData"
          ></el-input>
        </el-form-item>

        <el-form-item label="代码" class="formItemBoxStyle">
          <el-input
            v-model="queryParam.code"
            placeholder="请输入代码"
            @input="reloadCompanPlaceData"
          ></el-input>
        </el-form-item>

        <el-form-item label="科目" class="formItemBoxStyle">
          <el-select
            v-model="queryParam.subjectType"
            placeholder="请选择科目"
            @change="reloadCompanPlaceData"
          >
            <el-option label="全部" value></el-option>
            <el-option
              v-for="item in subjectOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button v-throttle type="primary" @click="reloadCompanPlaceData"
            >查询</el-button
          >
          <el-button v-throttle @click="refresh">重置</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div>
      <el-table
        v-loading="loading"
        border
        :data="placeDataList"
        tooltip-effect="dark"
        style="width: 100%"
        :header-cell-style="{
          'text-align': 'center',
          backgroundColor: '#dfe6ec',
        }"
        :cell-style="{ 'text-align': 'center' }"
      >
        <el-table-column
          prop="name"
          label="考点名称"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="code"
          label="代码"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="areaName"
          label="区县"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="subjectTypes"
          label="科目"
          show-overflow-tooltip
          :formatter="customFormatter"
        ></el-table-column>
        <el-table-column
          prop="seatCount"
          label="座位数"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="userName"
          label="联系人"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="phone"
          label="联系电话"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column label="操作" min-width="40px" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-button
              v-throttle
              size="mini"
              type="primary"
              @click="placeRoom(scope.row.code)"
              >查看考场</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div style="margin-top: 20px">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageParam.pageIndex"
        :page-size="pageParam.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageParam.total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { getCompanPlaceDataApi } from '@/api/schoolManufacturer/placeData.js'
import { isEmpty } from '@/utils/util.js'
export default {
  name: 'placeData',
  data() {
    return {
      queryParam: {
        name: '',
        code: '',
        subjectType: '',
      },
      loading: false,
      placeDataList: [],
      pageParam: {
        pageIndex: 1,
        pageSize: 10,
        total: 0,
      },
      subjectOptions: [],
    }
  },
  mounted() {
    this.renderSelect()
    this.init()
  },
  methods: {
    renderSelect() {
      this.subjectOptions = this.$Global.globalSubjectOptions
    },
    init() {
      this.getCompanPlaceData()
    },
    //获取考点数据-分页
    getCompanPlaceData() {
      this.loading = true
      getCompanPlaceDataApi(
        this.pageParam.pageIndex,
        this.pageParam.pageSize,
        this.queryParam
      )
        .then((res) => {
          this.loading = false
          if (res.success) {
            this.placeDataList = []
            this.placeDataList = res.data
            this.pageParam.total = 0
            this.pageParam.total = res.total
          } else {
            if (res.code != 500) {
              this.msgWarning(res.msg)
            }
          }
        })
        .catch(() => {
          this.loading = false
        })
    },
    reloadCompanPlaceData() {
      this.pageParam.pageIndex = 1
      this.getCompanPlaceData()
    },
    refresh() {
      this.queryParam.name = ''
      this.queryParam.code = ''
      this.queryParam.subjectType = ''
      this.reloadCompanPlaceData()
    },
    handleCurrentChange(val) {
      this.pageParam.pageIndex = val
      this.getCompanPlaceData()
    },
    handleSizeChange(val) {
      this.pageParam.pageSize = val
      this.getCompanPlaceData()
    },
    //查看考场
    placeRoom(val) {
      this.$router.push({
        path: '/schoolManufacturer/placeInfo',
        query: { examPlaceCode: val },
      })
    },
    customFormatter(row, column) {
      if (isEmpty(row)) {
        return
      }
      switch (column.property) {
        case 'subjectTypes':
          if (row.subjectTypes.length <= 0) {
            return '未知'
          } else {
            let appendReturn = ''
            row.subjectTypes.forEach((item, index) => {
              let subjectName = ''
              switch (item) {
                case 1:
                  subjectName = '物理'
                  break
                case 2:
                  subjectName = '生物'
                  break
                case 3:
                  subjectName = '化学'
                  break
              }
              appendReturn += subjectName
              if (index < row.subjectTypes.length - 1) {
                appendReturn += ','
              }
            })
            return appendReturn
          }
      }
    },
  },
}
</script>

<style></style>
